import { Inject } from '@nuxt/types/app'
import { Context } from '@nuxt/types'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { UserStore } from '~/store/userStore'
import { NotificationStore } from '~/store/notificationStore'
import { AppMessageBus, AppState } from '~/lib/app/appState'
import {
  AfterRequestHandler,
  BeforeRequestHandler,
  Fetcher,
  FetcherError,
  FetcherInit,
} from '~/lib/app/fetcher'
import { identifyUserOnUserDataLoadedHandler } from '~/lib/app/messageBus/messageHandlers/identifyUserOnUserDataLoadedHandler'
import { updateTalentActivityTimestampOnLoginHandler } from '~/lib/app/messageBus/messageHandlers/updateTalentActivityTimestampOnLoginHandler'
import { trackTalentReactivationInMixpanelHandler } from '~/lib/app/messageBus/messageHandlers/trackTalentReactivationInMixpanelHandler'
import { MessageBusInit } from '~/lib/app/messageBus/messageBus'
import { AuthService } from '~/lib/app/auth'
import { startAndStopUserDataPolling } from '~/lib/app/messageBus/messageHandlers/startAndStopUserDataPolling'
import { AppMessages } from '~/lib/app/appMessages'
import { getCookie } from '~/lib/utils/getCookie'
import firebase from 'firebase/compat'
import { Analytics } from '~/lib/app/analytics'

export interface CustomInjections {
  /** @deprecated use $app.appInsights */
  $appInsights: ApplicationInsights

  /** @deprecated use $app.user */
  $user: UserStore

  /** @deprecated use $app.notif */
  $notif: NotificationStore

  /** @deprecated use $app.fetcher */
  $fetcher: Fetcher

  $app: AppState
}

export default async function initApp(ctx: Context, inject: Inject) {
  const app = await AppState.init(
    ctx.$config,
    (locale) => ctx.i18n.setLocale(locale),
    configureMessageBus(),
    configureFetcher(ctx),
  )

  configureCookiebotListener(window, app.messageBus)
  trackAppExitInMixpanel(app.analytics)

  window.addEventListener('unhandledrejection', logUnhandledRejection(app.appInsights, app.notif))
  window.addEventListener('error', logUncaughtError(app.appInsights), { capture: true })
  inject('app', app)

  // Add our app state to the window for help debugging
  // please for the love of god don't use this in any components
  // @ts-ignore
  window.$hyre = app

  // old injections to not have to update all the code everywhere
  // we should work towards not needing these anymore
  inject('user', app.user)
  inject('mixpanel', app.analytics)
  inject('appInsights', app.appInsights)
  inject('fetcher', app.fetcher)
  inject('lang', app.lang)
  inject('notif', app.notif)
}

function trackAppExitInMixpanel(analytics: Analytics) {
  document.addEventListener('visibilitychange', function logData() {
    if (document.visibilityState === 'hidden') {
      analytics.track('User Exited App', {}, { transport: 'sendBeacon' })
    }
  })
}

/**
 * adapt the cookiebot window events in to our own event bus model
 */
function configureCookiebotListener(window: Window, messageBus: AppMessageBus) {
  const listener = () => {
    messageBus.publish('cookie-consent-updated', window.Cookiebot ?? {})
  }

  window.addEventListener('CookiebotOnLoad', listener)
}

function configureMessageBus() {
  return function (app: AppState, messageBus: MessageBusInit<AppMessages>) {
    messageBus.addConsumer(
      'user-data-loaded',
      identifyUserOnUserDataLoadedHandler(app.user, app.auth),
    )

    messageBus.addConsumer(
      'user-data-loaded',
      updateTalentActivityTimestampOnLoginHandler(app.fetcher, app.user, app.messageBus),
    )

    messageBus.addConsumer(
      'talent-reactivation-detected',
      trackTalentReactivationInMixpanelHandler(app.analytics),
    )

    messageBus.addConsumer('user-logged-out', () => app.user.reset())

    messageBus.addConsumer('auth-state-changed', startAndStopUserDataPolling(app.user))

    messageBus.addConsumer('cookie-consent-updated', ({ consent }) => {
      app.appInsights.getCookieMgr().setEnabled(!!consent?.statistics)
    })

    messageBus.addConsumer('cookie-consent-updated', ({ consent }) => {
      app.meta.updateConsent(!!consent?.marketing)
    })

    messageBus.addConsumer('user-data-loaded', updateTalentClickId(app.user, app.fetcher))
  }
}

const configureFetcher = (ctx: Context) => (app: AppState, fetcher: FetcherInit) => {
  fetcher.onBeforeRequest(setAuthorizationHeader(app.auth))
  fetcher.onBeforeRequest(setWebappPageHeader(ctx))
  fetcher.onBeforeRequest(setImpersonationHeader(app.user))
  fetcher.onBeforeRequest(setReferrerHeader())
  fetcher.onBeforeRequest(setUtmSearchParams())

  fetcher.onAfterRequest(logFailedRequestInTelemetry(app.appInsights))
  fetcher.onAfterRequest(goToLogoutWhenUnauthorized(ctx))
}

function setAuthorizationHeader(auth: AuthService): BeforeRequestHandler {
  return async ({ headers }) => {
    const token = await auth.getIdToken()
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
  }
}

function setWebappPageHeader(ctx: Context): BeforeRequestHandler {
  return async ({ headers }) => {
    if (ctx.route.name) {
      headers.set('X-Webapp-Page', ctx.route.name)
    }
  }
}

function setImpersonationHeader(user: UserStore): BeforeRequestHandler {
  return async ({ headers }) => {
    if (user.isImpersonating) {
      headers.set(
        'X-Hyre-Admin-Impersonated-User-Guid',
        !!user.companyId ? user.companyId : user.externalUserId!,
      )
    }
  }
}

function setReferrerHeader(): BeforeRequestHandler {
  return async ({ headers }) => {
    if (document.referrer) {
      headers.set('Referer', document.referrer)
    }
  }
}

function setUtmSearchParams(): BeforeRequestHandler {
  return async ({ searchParams }) => {
    const pageSearchParams = new URLSearchParams(location.search)
    for (const param of ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']) {
      if (pageSearchParams.has(param)) {
        searchParams.set(param, pageSearchParams.get(param)!)
      }
    }
  }
}

function goToLogoutWhenUnauthorized(ctx: Context): AfterRequestHandler {
  return async ({ status }) => {
    if (status === 401) {
      ctx.redirect('/logout')
    }
  }
}

function logFailedRequestInTelemetry(appInsights: ApplicationInsights): AfterRequestHandler {
  return async (data) => {
    if (data.status < 400) return
    console.log('handling failed request', data)
    appInsights.trackEvent({ name: 'failedRequest', properties: data })
  }
}

function updateTalentClickId(user: UserStore, fetcher: Fetcher) {
  let hasUpdatedClickId = false
  return async () => {
    if (hasUpdatedClickId) return

    if (!user.isTalent) return

    const clickId = getCookie('_fbc')

    if (!clickId) return

    await fetcher.$post(
      `/api/v2/talent/${user.externalUserId}/tracking-info` as `/api/v2/talent/{talentGuid}/tracking-info`,
      { clickId },
    )

    hasUpdatedClickId = true
  }
}

function logUnhandledRejection(appInsights: ApplicationInsights, notif: NotificationStore) {
  return function (ev: PromiseRejectionEvent) {
    appInsights.trackEvent({ name: 'unhandledrejection', properties: { reason: ev.reason } })

    if (ev.reason instanceof FetcherError) {
      notif.showError({
        message: ev.reason.message,
        button: {
          label: 'Fehler kopieren',
          icon: 'clipboard',
          action: () => navigator.clipboard.writeText(JSON.stringify(ev.reason.jsonBody)),
        },
      })
    } else {
      console.error(ev.reason)
    }
  }
}

function logUncaughtError(appInsights: ApplicationInsights) {
  return function (ev: ErrorEvent) {
    console.debug('uncaught error', ev)
    appInsights.trackException({ exception: ev.error })
  }
}
